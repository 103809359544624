var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.videoProcessing
        ? _c(
            "vs-alert",
            {
              staticClass: "text-warning",
              attrs: {
                title: _vm.$t("atencao"),
                color: "rgb(231, 154, 23)",
                active: "true",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("video-ainda-esta-em-processamento")) + " "
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "vjs-custom-skin" }, [
        _c(
          "video",
          {
            ref: "videoPlayer",
            staticClass: "video-js",
            attrs: { playsinline: "true", src: _vm.options.src },
            on: { timeupdate: _vm.onTimeUpdate },
          },
          [
            _c("source", {
              attrs: { src: _vm.options.src, type: _vm.options.type },
            }),
          ]
        ),
      ]),
      _vm.videoWaitProcessing
        ? _c("edu-placeholder-action", {
            staticClass:
              "absolute z-50 placeholder-backdrop text-white bottom-0",
            attrs: { label: _vm.$t("o-video-ainda-esta-sendo-processado") },
            on: {
              action: function ($event) {
                return _vm.$router.go()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }